import React from "react";
// import Header from "../../Header"
import "../about/Style.css";
import Aboutimg2 from "../../../assets/img/aboutimg2.webp";
import Aboutvisionlogo from "../../../assets/img/about-visionlogo.png";

function AboutVision() {
  return (
    <div className="Bgabout">
      <div className="container">
        {/* <Header textColor='white' /> */}
        <div className="lg:flex md:flex justify-between lg:mt-[30px] lg:mb-[100px] md:mb-[100px] md:mt-[30px]">
          <div>
            <div className="lg:mt-[35px] md:mt-[0px] mt-[35px]">
              <p className="text-[#fff] text-[48px] font-semibold z-10 lg:absolute  left-[40%]  text-center">
                About Metasoft
              </p>
            </div>
            <div className="lg:mt-[200px] md:mt-[0px] mt-[40px]">
              <div className="flex items-center gap-4 ">
                <p className="text-white text-[27px] font-bold flex items-center">
                  Vision
                </p>
                <img src={Aboutvisionlogo} alt="Aboutmissionlogo"></img>
              </div>
            </div>
            <div className="lg:w-[533px] md:w-[353px] mt-4">
              <p className="text-[#C5C5C5] text-[15px] font-thin text-start leading-[26px]">
                Our vision at Metasoft IT Solutions is to be a{" "}
                <span className="font-semibold text-white">
                  trusted partner, empowering businesses with cutting-edge
                </span>{" "}
                IT infrastructure solutions and innovative technologies. We
                strive to drive digital transformation, enabling our clients to
                achieve their goals, stay ahead of the competition, and thrive
                in the ever-evolving digital landscape
              </p>

              <p className="text-[#C5C5C5] text-[15px] mt-[40px] font-thin leading-[26px] text-start">
                Our vision at Metasoft IT Solutions is to be a trusted partner,
                empowering businesses with cutting-edge IT infrastructure
                solutions and innovative technologies. We strive to drive
                digital transformation, enabling our clients to achieve their
                goals, stay ahead of the competition, and thrive in the
                ever-evolving digital landscape
              </p>
            </div>
          </div>
          <div className="relative lg:mt-[0px] md:mt-[120px] mt-[30px]">
            <img src={Aboutimg2} alt="aboutimg2" className=""></img>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutVision;
