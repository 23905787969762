
import React, { useState } from 'react';
import "../ourbrands/Style.css";
import Menubg from "../../assets/img/Menu button.webp";
import Brandsbg4 from "../../assets/img/brandsbg3.webp";
import Brandsbg3 from "../../assets/img/brandsbg4.webp";
import Brandsbg2 from "../../assets/img/brandsbg2.webp";
import Brandsbg1 from "../../assets/img/brandsbg1.webp"; 
import Brandsbg from "../../assets/img/brandsbg.webp"; 
import Image1 from "../../assets/img/image1.webp"; 
import Image2 from "../../assets/img/image2.webp"; 
import Image3 from "../../assets/img/image3.webp"; 
import Image4 from "../../assets/img/image4.webp";
import grayscaleimg1 from "../../assets/img/grayscaleimg1.webp";
import grayscaleimg2 from "../../assets/img/grayscaleimg2.webp";
import grayscaleimg3 from "../../assets/img/grayscaleimg3.webp";
import grayscaleimg4 from "../../assets/img/grayscaleimg4.webp";
import Heder from "../Header";

import { Link } from 'react-router-dom';
import Model from "../model/Model";
const OurBrands = ({ activeLink }) => {
  const [hovered1, setHovered1] = useState(false);
  const [hovered2, setHovered2] = useState(false);
  const [hovered3, setHovered3] = useState(false);
  const [hovered4, setHovered4] = useState(false);
  const [backgroundImage, setBackgroundImage] = useState(Brandsbg);

  const handleMouseEnter = (imageNumber) => {
    if (imageNumber === 1) {
      setHovered1(true);
      setBackgroundImage(Brandsbg1);
    } else if (imageNumber === 2) {
      setHovered2(true);
      setBackgroundImage(Brandsbg2); // Change to the desired background image
    } else if (imageNumber === 3) {
      setHovered3(true);
      setBackgroundImage(Brandsbg3); // Change to the desired background image
    }
   else if (imageNumber === 4) {
    setHovered4(true);
    setBackgroundImage(Brandsbg4); // Change to the desired background image
  }
  };

  const handleMouseLeave = (imageNumber) => {
    if (imageNumber === 1) setHovered1(false);
    else if (imageNumber === 2) setHovered2(false);
    else if (imageNumber === 3) setHovered3(false);
    else if (imageNumber === 4) setHovered4(false);
  };

  return (
    <>
    
    <div className='bg-brands'
      style={{
        position: 'relative',
       
       
        backgroundImage: `url(${backgroundImage})`, // Set container background image
       
      }}
    >
      <div className='container'>
        <Heder textColor="white" />
        <div className="flex justify-end items-center ">
        <img src={Menubg} alt="menubg" data-bs-toggle="modal" data-bs-target="#menu"  className="fixed z-10 cursor-pointer bottom-0"></img>
      </div>
     <div>
      
      <p className='text-white text-center text-[40px] font-semibold'>Our Brands</p>
      <p className='text-[#D2CBCB] m-auto lg:w-[565px] text-center font-normal text-[13px]'>Empowering Efficiency and Functionality: Providing a Diverse Range of Hardware Solutions and Systems to Drive Technological Advancements.</p>
     </div>
      <div className='flex flex-wrap justify-center lg:items-center lg:mt-[20px] lg:gap-[30px] pb-[70px]'>
      <Link to="https://zeeqr.com/" target="_blank">
      <div className='lg:mt-[0px] mt-[60px]'>
        <img
          src={hovered1 ? Image1 : grayscaleimg1}
           // Set your image width
          alt="Image 1"
      
          style={{
            cursor: 'pointer',
            transition: 'transform 0.5s, filter 0.5s',
            transform: `scale(${hovered1 ? 1.1 : 1})`, // Zoom effect on hover
            filter: hovered1 ? 'none' : 'grayscale(100%)', // Grayscale effect on hover
            margin: '10px',
            height:'370px',
         
            objectFit: 'contain'
          }}
          onMouseEnter={() => handleMouseEnter(1)}
          onMouseLeave={() => handleMouseLeave(1)}
        />
      </div></Link>
      <Link to="https://oncloudwifi.co/" target="_blank">
      <div className='lg:mt-[0px] mt-[60px]'>
        <img
          src={hovered2 ? Image2 : grayscaleimg2}
           // Set your image width
          alt="Image 2"
          style={{
            cursor: 'pointer',
            transition: 'transform 0.5s, filter 0.5s',
            transform: `scale(${hovered2 ? 1.1 : 1})`,
            filter: hovered2 ? 'none' : 'grayscale(100%)',
            margin: '10px',
          
            height:'370px',
         
            objectFit: 'contain'
          }}
          onMouseEnter={() => handleMouseEnter(2)}
          onMouseLeave={() => handleMouseLeave(2)}
        />
      </div></Link>
      <div className='lg:mt-[0px] mt-[60px]'>
        <img
          src={hovered3 ? Image3 : grayscaleimg3}
         // Set your image width
          alt="Image 3"
          style={{
            cursor: 'pointer',
            transition: 'transform 0.5s, filter 0.5s',
            transform: `scale(${hovered3 ? 1.1 : 1})`,
            filter: hovered3 ? 'none' : 'grayscale(100%)',
            margin: '10px',
            
            height:'370px',
         
            objectFit: 'contain'
          }}
          onMouseEnter={() => handleMouseEnter(3)}
          onMouseLeave={() => handleMouseLeave(3)}
        />
      </div>
      <Link to="https://360views.netlify.app/" target="_blank">
      <div className='lg:mt-[0px] mt-[60px]'>
        <img
          src={hovered4 ? Image4 : grayscaleimg4}
         // Set your image width
          alt="Image 4"
          style={{
            cursor: 'pointer',
            transition: 'transform 0.5s, filter 0.5s',
            transform: `scale(${hovered4 ? 1.1 : 1})`,
            filter: hovered4 ? 'none' : 'grayscale(100%)',
            margin: '10px',
            height:'370px',
         
            objectFit: 'contain'
          }}
          onMouseEnter={() => handleMouseEnter(4)}
          onMouseLeave={() => handleMouseLeave(4)}

        />
     
      </div>
      </Link>
    </div>
    </div>
    </div>
    {/* <div class="menu modal  fade bd-example-modal-lg" id="menu" tabindex="-1" aria-labelledby="menu_Label" aria-hidden="true">
  
  <div class="modal-dialog modal-lg" role="document">
 
    <div class="modal-content">
    <div className='container'>
    <div class="modal-header">
             <img src={Metasoftlogo} alt='metasoftlogo'></img>
            </div>
      <div class="modal-body">
       <div className='flex justify-around mt-5'>

<div className=''>
<ul className='text-white text-[48px] font-normal text-start p-3'>
 <Link to="/" className={`no-underline ${activeLink === 'home' ? 'text-[#C52891]' : 'text-white'}`}><li className='hover:text-[#C52891]' >Home</li></Link>
 <Link to="/about" className={`no-underline ${activeLink === 'home' ? 'text-[#C52891]' : 'text-white'}`}> <li className='hover:text-[#C52891]'>About Us</li></Link>
 <Link to="/contact" className={`no-underline ${activeLink === 'home' ? 'text-[#C52891]' : 'text-white'}`}> <li className='hover:text-[#C52891]'>
  Get In Touch
  </li></Link>
 <Link to="/ourservice" className={`no-underline ${activeLink === 'home' ? 'text-[#C52891]' : 'text-white'}`}> <li className='hover:text-[#C52891]'>Our Services </li></Link>

 <Link to="/ourbrands" className={`no-underline ${activeLink === 'home' ? 'text-[#C52891]' : 'text-white'}`}><li className='hover:text-[#C52891]'>
  Our Brands
  </li></Link>
</ul>
  </div>
  <div><svg width="306" height="307" viewBox="0 0 306 307" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M153.391 197.049C183.745 197.049 208.146 222.189 207.999 253.244C207.852 283.56 183.231 307.074 151.7 307C122.081 307 99.2229 282.673 99.0024 251.322C98.7084 221.672 125.535 195.718 153.391 197.049Z" fill="#ED1E7C"/>
<path d="M151.558 109.999C123.973 110.147 100.14 86.8648 99.0364 55.9206C97.933 25.9342 122.061 0 152.441 0C182.379 0 205.992 22.1767 207.904 52.4578C209.596 79.6445 188.779 109.778 151.558 109.999Z" fill="#4EB7DA"/>
<path d="M53.6947 98.075C84.281 97.708 108.779 121.636 108.999 152.317C109.146 179.695 87.655 206.926 54.0615 207C24.0621 207.073 0.443855 183.145 0.00376583 152.024C-0.362975 123.471 26.1158 96.3868 53.6947 98.075Z" fill="#F0422B"/>
<path d="M250.879 98.0119C281.926 97.7919 305.853 122.068 306 153.091C306.073 180.887 283.247 207.363 249.704 206.996C220.566 206.63 197.519 182.427 197.005 151.624C196.565 122.068 224.235 96.3251 250.879 98.0853V98.0119Z" fill="#87C635"/>
</svg>
</div>
              </div>
   
      </div>
      <div class="modal-footer d-flex justify-content-between align-items-center gap-5">
      <ul className='d-flex justify-content-between align-items-center  gap-5 text-white text-[18px] font-normal'>
                <li>linkedin</li>
                <li>facebook</li>
                <li>instagram</li>
                <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
          <span aria-hidden="true" className='text-white text-[30px]'>&times;</span>
        </button>
              </ul>
              <div className='d-flex justify-content-between align-items-center'>
       
       <img src={Menuclose} alt='menubg' data-bs-toggle="modal" data-bs-target="#menu" className=''></img>
     </div>
            
</div>


    </div></div>
  </div>
</div> */}
 <Model activeLink={activeLink} />
    </>
  );
};

export default OurBrands;
