import React from "react";
// import Header from "../../Header"
import "../whymetasoft/Style.css";
import Logo1whymetasoft from "../../../assets/img/logo1whymetasoft.webp";
import Logo2whymetasoft from "../../../assets/img/logo2whymetasoft.webp";
import Logo3whymetasoft from "../../../assets/img/logo3whymetasoft.webp";
import Logo4whymetasoft from "../../../assets/img/logo4whymetasoft.webp";
import Whymetasoftlogo from "../../../assets/img/whymetasoftlogo.svg";
import { Link } from "react-router-dom";
function WhyMetasoft() {
  return (
    <>
      <div className="Bgwhymetasoft">
        <div className="container">
          {/* <Header textColor='white'/> */}
          <div className="lg:mt-[150px] md:mt-[100px] lg:mb-[50px] md:mb-[100px] mt-[40px]">
            <div className="lg:flex justify-between">
              <div className="">
                <div className="flex items-center gap-4 ">
                  <p className="text-white text-[27px] font-bold flex items-center">
                    Why Metasoft?
                  </p>
                  <img src={Whymetasoftlogo} alt="Aboutmissionlogo"></img>
                </div>

                <div className="lg:w-[553px] md:w-[553px] mt-5">
                  <p className="text-[#C5C5C5] text-[15px] font-thin text-start leading-[26px]">
                    Metasoft IT Solutions proudly serves as an authorized
                    partner of renowned technology giants including{" "}
                    <span className="font-semibold text-white">
                      Dell, Lenovo, Microsoft, Adobe,
                    </span>{" "}
                    and more. As a trusted partner, we offer access to the
                    latest products and solutions from these industry leaders.
                  </p>

                  <p className="text-[#C5C5C5] text-[15px] mt-[40px] font-thin leading-[26px] text-start">
                    Our expertise combined with our authorized partner status
                    ensures that you receive{" "}
                    <span className="font-semibold text-white">
                      genuine, high-quality{" "}
                    </span>
                    hardware and software solutions to drive your business
                    forward. With our strong partnerships and direct access to
                    these industry-leading brands, we can offer competitive
                    pricing on a wide range of
                    <span className="font-semibold">
                      {" "}
                      hardware and software solutions
                    </span>
                  </p>
                </div>
              </div>
              <div></div>
              <div>
                <div className="flex md:justify-center lg:justify-between items-center lg:mt-[0px] md:mt-[20px] mt-[30px] gap-[50px]">
                  <div className="">
                    <div className="flex justify-center items-center">
                      <img src={Logo1whymetasoft} alt="Logo1whymetasoft"></img>
                    </div>
                    <p className="text-white text-[12px] font-bold text-center p-3">
                      Trusted Partnerships
                    </p>
                  </div>
                  <div>
                    <div className="flex justify-center items-center">
                      <img src={Logo2whymetasoft} alt="Logo1whymetasoft"></img>
                    </div>
                    <p className="text-white text-[12px] font-bold text-center pt-3">
                      Customer-Centric Approach
                    </p>
                  </div>
                </div>
                <div className="flex md:justify-center lg:justify-between gap-[50px] items-center mt-[60px]">
                  <div>
                    <div className="flex justify-center  items-center">
                      <img src={Logo3whymetasoft} alt="Logo1whymetasoft"></img>
                    </div>
                    <p className="text-white text-[12px] font-bold text-center p-3">
                      End-to-End Solutions
                    </p>
                  </div>
                  <div>
                    <div className="flex justify-center items-center">
                      <img src={Logo4whymetasoft} alt="Logo1whymetasoft"></img>
                    </div>
                    <p className="text-white text-[12px] font-bold text-center pt-3">
                      Expertise & Experience
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex justify-center mt-[30px]">
              <Link to="/about">
                {" "}
                <button className="btnservice">Know More</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default WhyMetasoft;
