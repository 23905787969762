import React, { useState } from "react";
import Header from "../Header";
import Framecontact from "../../assets/img/framecontact.webp";
import "../contact/Style.css";
import { LuUpload } from "react-icons/lu";
import Menubg from "../../assets/img/Menu button.webp";
import Metasoftlogo from "../../assets/img/metasoftlogo.svg";

import { Link } from "react-router-dom";
import Model from "../model/Model";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import emailjs from "@emailjs/browser";
function OurCareers({ activeLink }) {
  const notify = () => toast("Thank you for contact. We will contact Shortly");
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    address: "",
    message: "",
  });
  const showToast = (message, type = "success") => {
    toast[type](message, {
      position: "top-right",
      autoClose: 3000, // Close the toast after 3 seconds
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };
  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    // Clear validation error when user types
    setErrors({
      ...errors,
      [name]: undefined,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Perform validation checks
    const validationErrors = {};
    if (!formData.name.trim()) {
      validationErrors.name = "Name is required";
    }
    if (!formData.email.trim()) {
      validationErrors.email = "Email is required";
    } else if (!/^\S+@\S+\.\S+$/.test(formData.email)) {
      validationErrors.email = "Invalid email format";
    }
    if (!formData.mobile.trim()) {
      validationErrors.mobile = "Mobile number is required";
    } else if (!/^\d{10}$/.test(formData.mobile)) {
      validationErrors.mobile = "Invalid mobile number";
    }
    if (!formData.address.trim()) {
      validationErrors.address = "address is required";
    }
    if (!formData.message.trim()) {
      validationErrors.message = "Message is required";
    }

    // Update errors state with validation errors
    setErrors(validationErrors);

    // If no validation errors, submit the form
    if (Object.keys(validationErrors).length === 0) {
      // Perform form submission logic here (e.g., send data to server)
      // console.log("Form submitted:", formData);
      console.log("we reached here", formData);
      emailjs
        .sendForm(
          "service_cdb60t5",
          "template_8c5gsbe",
          formData.current,
          "p87NmtLp7L4bobmxM"
        )
        .then((response) => {
          console.log("Email sent successfully:", formData);
          showToast("Email sent successfully", "success");
        })

        .catch((error) => {
          console.error("Error sending email:", error);
          showToast("Error sending email", "error");
        });
    }
  };
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };
  const handleUpload = () => {
    if (selectedFile) {
      console.log("Uploading CV:", selectedFile);

      // Perform the upload logic here. Adjust the server endpoint as needed.

      const formData = new FormData();
      formData.append("cv", selectedFile);

      fetch("/upload-cv", {
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((data) => {
          console.log("CV upload successful:", data);
          // Handle the response from the server as needed.
        })
        .catch((error) => {
          console.error("Error uploading CV:", error);
        });
    } else {
      console.error("No CV selected");
    }
  };

  return (
    <>
      <div className="bg-contact relative">
        <div className="container">
          <Header textColor="white" />
          <div className="flex justify-end items-center ">
            <img
              src={Menubg}
              alt="menubg"
              data-bs-toggle="modal"
              data-bs-target="#menu"
              className="fixed z-10 cursor-pointer bottom-0 "
            ></img>
          </div>
          <div className="flex  md:justify-around justify-center flex-wrap lg:justify-around mt-[30px]">
            <div className="">
              <img src={Framecontact} alt="Framecontact" className=""></img>
            </div>

            <div className="lg:mt-[0px] md:mt-0  lg:pb-0 md:pb-0 pb-5">
              <h2 className="text-white text-[38px] font-light lg:text-start md:text-start ">
                Contact Us
              </h2>
              <form ref={formData} onSubmit={handleSubmit}>
                <ToastContainer />
                <div className="flex flex-wrap mt-4  md:justify-between justify-center lg:justify-between sm:gap-[100px]">
                  <div>
                    <div className="mb-3">
                      <label
                        htmlFor="name"
                        className="block text-start text-[18px] font-light text-white"
                      >
                        Name*
                      </label>
                      <input
                        type="text"
                        id="name"
                        name="name"
                        className="w-full py-2 border-b w-100 border-white focus:outline-none text-white  bg-transparent focus:border-red-700"
                        value={formData.name}
                        onChange={handleInputChange}
                      />
                      <div className="text-start">
                        {errors.name && (
                          <span className="text-red-500">{errors.name}</span>
                        )}
                      </div>
                    </div>

                    <div className="mb-3">
                      <label
                        htmlFor="email"
                        className="block text-start text-[18px] font-light text-white"
                      >
                        Email*
                      </label>
                      <input
                        type="text"
                        id="email"
                        name="email"
                        className="w-100 py-2 border-b text-start border-white text-white  focus:outline-none bg-transparent focus:border-red-700"
                        value={formData.email}
                        onChange={handleInputChange}
                      />
                      <div className="text-start">
                        {errors.email && (
                          <span className="text-red-500">{errors.email}</span>
                        )}
                      </div>
                    </div>
                    <div className="mb-3">
                      <label
                        htmlFor="mobile"
                        className="block text-start text-[18px] font-light text-white"
                      >
                        Phone Number*
                      </label>
                      <input
                        type="tel" // Use 'tel' type for phone numbers
                        id="mobile"
                        name="mobile"
                        className="w-full py-2 border-b text-start border-white text-white focus:outline-none bg-transparent focus:border-red-700"
                        value={formData.mobile}
                        onChange={handleInputChange}
                      />
                      <div className="text-start">
                        {errors.mobile && (
                          <span className="text-red-500">{errors.mobile}</span>
                        )}
                      </div>
                    </div>

                    <div className="mb-3">
                      <label
                        htmlFor="address"
                        className="block text-start text-[18px] font-light text-white"
                      >
                        Address*
                      </label>
                      <input
                        type="text"
                        id="address"
                        name="address"
                        className="w-full py-2 border-b border-white text-white  focus:outline-none bg-transparent focus:border-red-700"
                        value={formData.address}
                        onChange={handleInputChange}
                      />
                      <div className="text-start ">
                        {errors.address && (
                          <span className="text-red-500 ">
                            {errors.address}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="w-100">
                      <label
                        htmlFor="message"
                        className="block w-100  text-start text-[18px] font-light text-white"
                      >
                        Your Messages*
                      </label>

                      <textarea
                        id="message"
                        className="w-100 p-1 border h-[100px] mt-3 text-white border-white focus:outline-none bg-transparent focus:border-red-700"
                        name="message"
                        value={formData.message}
                        onChange={handleInputChange}
                      ></textarea>
                      <div className="text-start">
                        {errors.message && (
                          <span className="text-red-500">{errors.message}</span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex justify-start mt-5 mb-4 justify-content-center justify-content-md-start">
                  <button
                    type="submit"
                    className=" text-[18px] font-normal pt-[10px] pb-[10px] pl-[47px] pr-[47px]  text-center text-white rounded-[40px] border-white border-1 border"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Model activeLink={activeLink} />
    </>
  );
}

export default OurCareers;
