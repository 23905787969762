import React from "react";
// import Header from "../../Header"
import "../about/Style.css";
import Aboutimg1 from "../../../assets/img/aboutimg1.webp";
import Aboutmissionlogo from "../../../assets/img/about-mission-logo.png";
function About() {
  return (
    <>
      <div className="Bgabout">
        <div className="container">
          {/* <Header textColor='white' /> */}
          <div className="lg:flex md:flex justify-between lg:mt-[100px] lg:mb-[100px] md:mb-[100px] md:mt-[100px]">
            <div>
              <div className="lg:mt-[35px] md:mt-[0px] mt-[35px]">
                <p className="text-[#fff] text-[48px] font-semibold z-10 lg:absolute  left-[40%]  text-center">
                  About Metasoft
                </p>
              </div>
              <div className="lg:mt-[200px] md:mt-[0px] mt-[40px]">
                <div className="flex items-center gap-4 ">
                  <p className="text-white text-[27px] font-bold flex items-center">
                    Mission
                  </p>
                  <img src={Aboutmissionlogo} alt="Aboutmissionlogo"></img>
                </div>
              </div>
              <div className="lg:w-[533px] md:w-[353px]  mt-4">
                <p className="text-[#C5C5C5] text-[15px] font-thin text-start leading-[26px]">
                  Metasoft IT Solutions proudly serves as an authorized partner
                  of renowned technology giants including{" "}
                  <span className="font-semibold text-white">
                    Dell, Lenovo, Microsoft, Adobe,
                  </span>{" "}
                  and more. As a trusted partner, we offer access to the latest
                  products and solutions from these industry leaders.
                </p>

                <p className="text-[#C5C5C5] text-[15px] mt-[40px] font-thin leading-[26px] text-start">
                  Our expertise combined with our authorized partner status
                  ensures that you receive{" "}
                  <span className="font-semibold text-white">
                    genuine, high-quality{" "}
                  </span>
                  hardware and software solutions to drive your business
                  forward. With our strong partnerships and direct access to
                  these industry-leading brands, we can offer competitive
                  pricing on a wide range of
                  <span className="font-semibold">
                    {" "}
                    hardware and software solutions
                  </span>
                </p>
              </div>
            </div>
            <div className="relative lg:mt-[0px] md:mt-[130px] mt-[30px]">
              <img src={Aboutimg1} alt="aboutimg1" className=""></img>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default About;
