import React from "react";
// import Header from "../../Header"
import "../testimonial/Style.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function Testimonial() {
  const settings = {
    // dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    autoplayTimeout: 1500,
    smartSpeed: 450,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <div className="BgTestimonail">
      <div className="container">
        {/* <Header textColor='white' /> */}
        <div className="mb-[150px]">
          <div className="lg:mt-[70px] md:mt-[100px]">
            <p className="text-white text-center text-[48px] font-semibold">
              Voices from Our Satisfied Partners
            </p>
          </div>
          <div className="testimonial-slider mt-[100px]">
            <Slider
              {...settings}
              className="lg:ml-[0px] md:ml-[0px] mg:ml-[0px] lg:ml-[0px] ml-[20px] mr-[20px]"
            >
              <div>
                <div className="testimonial-card">
                  <div className="flex justify-center">
                    <svg
                      width="89"
                      height="88"
                      viewBox="0 0 89 88"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="44.5" cy="44" r="44" fill="#BC0069" />
                    </svg>
                  </div>
                  <h3>Saritha</h3>
                  <div className="flex justify-center">
                    <svg
                      width="131"
                      height="26"
                      viewBox="0 0 131 26"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12.5489 2.92705C12.8483 2.00574 14.1517 2.00574 14.4511 2.92705L16.1942 8.29179C16.328 8.70382 16.712 8.98278 17.1452 8.98278H22.7861C23.7548 8.98278 24.1576 10.2224 23.3738 10.7918L18.8103 14.1074C18.4598 14.362 18.3132 14.8134 18.447 15.2254L20.1902 20.5902C20.4895 21.5115 19.435 22.2776 18.6513 21.7082L14.0878 18.3926C13.7373 18.138 13.2627 18.138 12.9122 18.3926L8.34869 21.7082C7.56498 22.2776 6.5105 21.5115 6.80985 20.5902L8.55296 15.2254C8.68683 14.8134 8.54018 14.362 8.18969 14.1074L3.62616 10.7918C2.84245 10.2224 3.24523 8.98278 4.21395 8.98278H9.85477C10.288 8.98278 10.672 8.70382 10.8058 8.2918L12.5489 2.92705Z"
                        fill="#FF8A00"
                      />
                      <path
                        d="M38.5489 2.92705C38.8483 2.00574 40.1517 2.00574 40.4511 2.92705L42.1942 8.29179C42.328 8.70382 42.712 8.98278 43.1452 8.98278H48.7861C49.7548 8.98278 50.1576 10.2224 49.3738 10.7918L44.8103 14.1074C44.4598 14.362 44.3132 14.8134 44.447 15.2254L46.1902 20.5902C46.4895 21.5115 45.435 22.2776 44.6513 21.7082L40.0878 18.3926C39.7373 18.138 39.2627 18.138 38.9122 18.3926L34.3487 21.7082C33.565 22.2776 32.5105 21.5115 32.8098 20.5902L34.553 15.2254C34.6868 14.8134 34.5402 14.362 34.1897 14.1074L29.6262 10.7918C28.8425 10.2224 29.2452 8.98278 30.2139 8.98278H35.8548C36.288 8.98278 36.672 8.70382 36.8058 8.2918L38.5489 2.92705Z"
                        fill="#FF8A00"
                      />
                      <path
                        d="M64.5489 2.92705C64.8483 2.00574 66.1517 2.00574 66.4511 2.92705L68.1942 8.29179C68.328 8.70382 68.712 8.98278 69.1452 8.98278H74.7861C75.7548 8.98278 76.1576 10.2224 75.3738 10.7918L70.8103 14.1074C70.4598 14.362 70.3132 14.8134 70.447 15.2254L72.1902 20.5902C72.4895 21.5115 71.435 22.2776 70.6513 21.7082L66.0878 18.3926C65.7373 18.138 65.2627 18.138 64.9122 18.3926L60.3487 21.7082C59.565 22.2776 58.5105 21.5115 58.8098 20.5902L60.553 15.2254C60.6868 14.8134 60.5402 14.362 60.1897 14.1074L55.6262 10.7918C54.8425 10.2224 55.2452 8.98278 56.2139 8.98278H61.8548C62.288 8.98278 62.672 8.70382 62.8058 8.2918L64.5489 2.92705Z"
                        fill="#FF8A00"
                      />
                      <path
                        d="M90.5489 2.92705C90.8483 2.00574 92.1517 2.00574 92.4511 2.92705L94.1942 8.29179C94.328 8.70382 94.712 8.98278 95.1452 8.98278H100.786C101.755 8.98278 102.158 10.2224 101.374 10.7918L96.8103 14.1074C96.4598 14.362 96.3132 14.8134 96.447 15.2254L98.1902 20.5902C98.4895 21.5115 97.435 22.2776 96.6513 21.7082L92.0878 18.3926C91.7373 18.138 91.2627 18.138 90.9122 18.3926L86.3487 21.7082C85.565 22.2776 84.5105 21.5115 84.8098 20.5902L86.553 15.2254C86.6868 14.8134 86.5402 14.362 86.1897 14.1074L81.6262 10.7918C80.8425 10.2224 81.2452 8.98278 82.2139 8.98278H87.8548C88.288 8.98278 88.672 8.70382 88.8058 8.2918L90.5489 2.92705Z"
                        fill="#FF8A00"
                      />
                      <path
                        d="M116.549 2.92705C116.848 2.00574 118.152 2.00574 118.451 2.92705L120.194 8.29179C120.328 8.70382 120.712 8.98278 121.145 8.98278H126.786C127.755 8.98278 128.158 10.2224 127.374 10.7918L122.81 14.1074C122.46 14.362 122.313 14.8134 122.447 15.2254L124.19 20.5902C124.49 21.5115 123.435 22.2776 122.651 21.7082L118.088 18.3926C117.737 18.138 117.263 18.138 116.912 18.3926L112.349 21.7082C111.565 22.2776 110.51 21.5115 110.81 20.5902L112.553 15.2254C112.687 14.8134 112.54 14.362 112.19 14.1074L107.626 10.7918C106.842 10.2224 107.245 8.98278 108.214 8.98278H113.855C114.288 8.98278 114.672 8.70382 114.806 8.2918L116.549 2.92705Z"
                        fill="#FF8A00"
                      />
                    </svg>
                  </div>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.
                  </p>
                </div>
              </div>
              <div>
                <div className="testimonial-card">
                  <div className="flex justify-center">
                    <svg
                      width="89"
                      height="88"
                      viewBox="0 0 89 88"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="44.5" cy="44" r="44" fill="#BC0069" />
                    </svg>
                  </div>
                  <h3>Saritha</h3>
                  <div className="flex justify-center">
                    <svg
                      width="131"
                      height="26"
                      viewBox="0 0 131 26"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12.5489 2.92705C12.8483 2.00574 14.1517 2.00574 14.4511 2.92705L16.1942 8.29179C16.328 8.70382 16.712 8.98278 17.1452 8.98278H22.7861C23.7548 8.98278 24.1576 10.2224 23.3738 10.7918L18.8103 14.1074C18.4598 14.362 18.3132 14.8134 18.447 15.2254L20.1902 20.5902C20.4895 21.5115 19.435 22.2776 18.6513 21.7082L14.0878 18.3926C13.7373 18.138 13.2627 18.138 12.9122 18.3926L8.34869 21.7082C7.56498 22.2776 6.5105 21.5115 6.80985 20.5902L8.55296 15.2254C8.68683 14.8134 8.54018 14.362 8.18969 14.1074L3.62616 10.7918C2.84245 10.2224 3.24523 8.98278 4.21395 8.98278H9.85477C10.288 8.98278 10.672 8.70382 10.8058 8.2918L12.5489 2.92705Z"
                        fill="#FF8A00"
                      />
                      <path
                        d="M38.5489 2.92705C38.8483 2.00574 40.1517 2.00574 40.4511 2.92705L42.1942 8.29179C42.328 8.70382 42.712 8.98278 43.1452 8.98278H48.7861C49.7548 8.98278 50.1576 10.2224 49.3738 10.7918L44.8103 14.1074C44.4598 14.362 44.3132 14.8134 44.447 15.2254L46.1902 20.5902C46.4895 21.5115 45.435 22.2776 44.6513 21.7082L40.0878 18.3926C39.7373 18.138 39.2627 18.138 38.9122 18.3926L34.3487 21.7082C33.565 22.2776 32.5105 21.5115 32.8098 20.5902L34.553 15.2254C34.6868 14.8134 34.5402 14.362 34.1897 14.1074L29.6262 10.7918C28.8425 10.2224 29.2452 8.98278 30.2139 8.98278H35.8548C36.288 8.98278 36.672 8.70382 36.8058 8.2918L38.5489 2.92705Z"
                        fill="#FF8A00"
                      />
                      <path
                        d="M64.5489 2.92705C64.8483 2.00574 66.1517 2.00574 66.4511 2.92705L68.1942 8.29179C68.328 8.70382 68.712 8.98278 69.1452 8.98278H74.7861C75.7548 8.98278 76.1576 10.2224 75.3738 10.7918L70.8103 14.1074C70.4598 14.362 70.3132 14.8134 70.447 15.2254L72.1902 20.5902C72.4895 21.5115 71.435 22.2776 70.6513 21.7082L66.0878 18.3926C65.7373 18.138 65.2627 18.138 64.9122 18.3926L60.3487 21.7082C59.565 22.2776 58.5105 21.5115 58.8098 20.5902L60.553 15.2254C60.6868 14.8134 60.5402 14.362 60.1897 14.1074L55.6262 10.7918C54.8425 10.2224 55.2452 8.98278 56.2139 8.98278H61.8548C62.288 8.98278 62.672 8.70382 62.8058 8.2918L64.5489 2.92705Z"
                        fill="#FF8A00"
                      />
                      <path
                        d="M90.5489 2.92705C90.8483 2.00574 92.1517 2.00574 92.4511 2.92705L94.1942 8.29179C94.328 8.70382 94.712 8.98278 95.1452 8.98278H100.786C101.755 8.98278 102.158 10.2224 101.374 10.7918L96.8103 14.1074C96.4598 14.362 96.3132 14.8134 96.447 15.2254L98.1902 20.5902C98.4895 21.5115 97.435 22.2776 96.6513 21.7082L92.0878 18.3926C91.7373 18.138 91.2627 18.138 90.9122 18.3926L86.3487 21.7082C85.565 22.2776 84.5105 21.5115 84.8098 20.5902L86.553 15.2254C86.6868 14.8134 86.5402 14.362 86.1897 14.1074L81.6262 10.7918C80.8425 10.2224 81.2452 8.98278 82.2139 8.98278H87.8548C88.288 8.98278 88.672 8.70382 88.8058 8.2918L90.5489 2.92705Z"
                        fill="#FF8A00"
                      />
                      <path
                        d="M116.549 2.92705C116.848 2.00574 118.152 2.00574 118.451 2.92705L120.194 8.29179C120.328 8.70382 120.712 8.98278 121.145 8.98278H126.786C127.755 8.98278 128.158 10.2224 127.374 10.7918L122.81 14.1074C122.46 14.362 122.313 14.8134 122.447 15.2254L124.19 20.5902C124.49 21.5115 123.435 22.2776 122.651 21.7082L118.088 18.3926C117.737 18.138 117.263 18.138 116.912 18.3926L112.349 21.7082C111.565 22.2776 110.51 21.5115 110.81 20.5902L112.553 15.2254C112.687 14.8134 112.54 14.362 112.19 14.1074L107.626 10.7918C106.842 10.2224 107.245 8.98278 108.214 8.98278H113.855C114.288 8.98278 114.672 8.70382 114.806 8.2918L116.549 2.92705Z"
                        fill="#FF8A00"
                      />
                    </svg>
                  </div>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.
                  </p>
                </div>
              </div>
              <div>
                <div className="testimonial-card">
                  <div className="flex justify-center">
                    <svg
                      width="89"
                      height="88"
                      viewBox="0 0 89 88"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="44.5" cy="44" r="44" fill="#BC0069" />
                    </svg>
                  </div>
                  <h3>Saritha</h3>
                  <div className="flex justify-center">
                    <svg
                      width="131"
                      height="26"
                      viewBox="0 0 131 26"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12.5489 2.92705C12.8483 2.00574 14.1517 2.00574 14.4511 2.92705L16.1942 8.29179C16.328 8.70382 16.712 8.98278 17.1452 8.98278H22.7861C23.7548 8.98278 24.1576 10.2224 23.3738 10.7918L18.8103 14.1074C18.4598 14.362 18.3132 14.8134 18.447 15.2254L20.1902 20.5902C20.4895 21.5115 19.435 22.2776 18.6513 21.7082L14.0878 18.3926C13.7373 18.138 13.2627 18.138 12.9122 18.3926L8.34869 21.7082C7.56498 22.2776 6.5105 21.5115 6.80985 20.5902L8.55296 15.2254C8.68683 14.8134 8.54018 14.362 8.18969 14.1074L3.62616 10.7918C2.84245 10.2224 3.24523 8.98278 4.21395 8.98278H9.85477C10.288 8.98278 10.672 8.70382 10.8058 8.2918L12.5489 2.92705Z"
                        fill="#FF8A00"
                      />
                      <path
                        d="M38.5489 2.92705C38.8483 2.00574 40.1517 2.00574 40.4511 2.92705L42.1942 8.29179C42.328 8.70382 42.712 8.98278 43.1452 8.98278H48.7861C49.7548 8.98278 50.1576 10.2224 49.3738 10.7918L44.8103 14.1074C44.4598 14.362 44.3132 14.8134 44.447 15.2254L46.1902 20.5902C46.4895 21.5115 45.435 22.2776 44.6513 21.7082L40.0878 18.3926C39.7373 18.138 39.2627 18.138 38.9122 18.3926L34.3487 21.7082C33.565 22.2776 32.5105 21.5115 32.8098 20.5902L34.553 15.2254C34.6868 14.8134 34.5402 14.362 34.1897 14.1074L29.6262 10.7918C28.8425 10.2224 29.2452 8.98278 30.2139 8.98278H35.8548C36.288 8.98278 36.672 8.70382 36.8058 8.2918L38.5489 2.92705Z"
                        fill="#FF8A00"
                      />
                      <path
                        d="M64.5489 2.92705C64.8483 2.00574 66.1517 2.00574 66.4511 2.92705L68.1942 8.29179C68.328 8.70382 68.712 8.98278 69.1452 8.98278H74.7861C75.7548 8.98278 76.1576 10.2224 75.3738 10.7918L70.8103 14.1074C70.4598 14.362 70.3132 14.8134 70.447 15.2254L72.1902 20.5902C72.4895 21.5115 71.435 22.2776 70.6513 21.7082L66.0878 18.3926C65.7373 18.138 65.2627 18.138 64.9122 18.3926L60.3487 21.7082C59.565 22.2776 58.5105 21.5115 58.8098 20.5902L60.553 15.2254C60.6868 14.8134 60.5402 14.362 60.1897 14.1074L55.6262 10.7918C54.8425 10.2224 55.2452 8.98278 56.2139 8.98278H61.8548C62.288 8.98278 62.672 8.70382 62.8058 8.2918L64.5489 2.92705Z"
                        fill="#FF8A00"
                      />
                      <path
                        d="M90.5489 2.92705C90.8483 2.00574 92.1517 2.00574 92.4511 2.92705L94.1942 8.29179C94.328 8.70382 94.712 8.98278 95.1452 8.98278H100.786C101.755 8.98278 102.158 10.2224 101.374 10.7918L96.8103 14.1074C96.4598 14.362 96.3132 14.8134 96.447 15.2254L98.1902 20.5902C98.4895 21.5115 97.435 22.2776 96.6513 21.7082L92.0878 18.3926C91.7373 18.138 91.2627 18.138 90.9122 18.3926L86.3487 21.7082C85.565 22.2776 84.5105 21.5115 84.8098 20.5902L86.553 15.2254C86.6868 14.8134 86.5402 14.362 86.1897 14.1074L81.6262 10.7918C80.8425 10.2224 81.2452 8.98278 82.2139 8.98278H87.8548C88.288 8.98278 88.672 8.70382 88.8058 8.2918L90.5489 2.92705Z"
                        fill="#FF8A00"
                      />
                      <path
                        d="M116.549 2.92705C116.848 2.00574 118.152 2.00574 118.451 2.92705L120.194 8.29179C120.328 8.70382 120.712 8.98278 121.145 8.98278H126.786C127.755 8.98278 128.158 10.2224 127.374 10.7918L122.81 14.1074C122.46 14.362 122.313 14.8134 122.447 15.2254L124.19 20.5902C124.49 21.5115 123.435 22.2776 122.651 21.7082L118.088 18.3926C117.737 18.138 117.263 18.138 116.912 18.3926L112.349 21.7082C111.565 22.2776 110.51 21.5115 110.81 20.5902L112.553 15.2254C112.687 14.8134 112.54 14.362 112.19 14.1074L107.626 10.7918C106.842 10.2224 107.245 8.98278 108.214 8.98278H113.855C114.288 8.98278 114.672 8.70382 114.806 8.2918L116.549 2.92705Z"
                        fill="#FF8A00"
                      />
                    </svg>
                  </div>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.
                  </p>
                </div>
              </div>
              <div>
                <div className="testimonial-card">
                  <div className="flex justify-center">
                    <svg
                      width="89"
                      height="88"
                      viewBox="0 0 89 88"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="44.5" cy="44" r="44" fill="#BC0069" />
                    </svg>
                  </div>
                  <h3>Saritha</h3>
                  <div className="flex justify-center">
                    <svg
                      width="131"
                      height="26"
                      viewBox="0 0 131 26"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12.5489 2.92705C12.8483 2.00574 14.1517 2.00574 14.4511 2.92705L16.1942 8.29179C16.328 8.70382 16.712 8.98278 17.1452 8.98278H22.7861C23.7548 8.98278 24.1576 10.2224 23.3738 10.7918L18.8103 14.1074C18.4598 14.362 18.3132 14.8134 18.447 15.2254L20.1902 20.5902C20.4895 21.5115 19.435 22.2776 18.6513 21.7082L14.0878 18.3926C13.7373 18.138 13.2627 18.138 12.9122 18.3926L8.34869 21.7082C7.56498 22.2776 6.5105 21.5115 6.80985 20.5902L8.55296 15.2254C8.68683 14.8134 8.54018 14.362 8.18969 14.1074L3.62616 10.7918C2.84245 10.2224 3.24523 8.98278 4.21395 8.98278H9.85477C10.288 8.98278 10.672 8.70382 10.8058 8.2918L12.5489 2.92705Z"
                        fill="#FF8A00"
                      />
                      <path
                        d="M38.5489 2.92705C38.8483 2.00574 40.1517 2.00574 40.4511 2.92705L42.1942 8.29179C42.328 8.70382 42.712 8.98278 43.1452 8.98278H48.7861C49.7548 8.98278 50.1576 10.2224 49.3738 10.7918L44.8103 14.1074C44.4598 14.362 44.3132 14.8134 44.447 15.2254L46.1902 20.5902C46.4895 21.5115 45.435 22.2776 44.6513 21.7082L40.0878 18.3926C39.7373 18.138 39.2627 18.138 38.9122 18.3926L34.3487 21.7082C33.565 22.2776 32.5105 21.5115 32.8098 20.5902L34.553 15.2254C34.6868 14.8134 34.5402 14.362 34.1897 14.1074L29.6262 10.7918C28.8425 10.2224 29.2452 8.98278 30.2139 8.98278H35.8548C36.288 8.98278 36.672 8.70382 36.8058 8.2918L38.5489 2.92705Z"
                        fill="#FF8A00"
                      />
                      <path
                        d="M64.5489 2.92705C64.8483 2.00574 66.1517 2.00574 66.4511 2.92705L68.1942 8.29179C68.328 8.70382 68.712 8.98278 69.1452 8.98278H74.7861C75.7548 8.98278 76.1576 10.2224 75.3738 10.7918L70.8103 14.1074C70.4598 14.362 70.3132 14.8134 70.447 15.2254L72.1902 20.5902C72.4895 21.5115 71.435 22.2776 70.6513 21.7082L66.0878 18.3926C65.7373 18.138 65.2627 18.138 64.9122 18.3926L60.3487 21.7082C59.565 22.2776 58.5105 21.5115 58.8098 20.5902L60.553 15.2254C60.6868 14.8134 60.5402 14.362 60.1897 14.1074L55.6262 10.7918C54.8425 10.2224 55.2452 8.98278 56.2139 8.98278H61.8548C62.288 8.98278 62.672 8.70382 62.8058 8.2918L64.5489 2.92705Z"
                        fill="#FF8A00"
                      />
                      <path
                        d="M90.5489 2.92705C90.8483 2.00574 92.1517 2.00574 92.4511 2.92705L94.1942 8.29179C94.328 8.70382 94.712 8.98278 95.1452 8.98278H100.786C101.755 8.98278 102.158 10.2224 101.374 10.7918L96.8103 14.1074C96.4598 14.362 96.3132 14.8134 96.447 15.2254L98.1902 20.5902C98.4895 21.5115 97.435 22.2776 96.6513 21.7082L92.0878 18.3926C91.7373 18.138 91.2627 18.138 90.9122 18.3926L86.3487 21.7082C85.565 22.2776 84.5105 21.5115 84.8098 20.5902L86.553 15.2254C86.6868 14.8134 86.5402 14.362 86.1897 14.1074L81.6262 10.7918C80.8425 10.2224 81.2452 8.98278 82.2139 8.98278H87.8548C88.288 8.98278 88.672 8.70382 88.8058 8.2918L90.5489 2.92705Z"
                        fill="#FF8A00"
                      />
                      <path
                        d="M116.549 2.92705C116.848 2.00574 118.152 2.00574 118.451 2.92705L120.194 8.29179C120.328 8.70382 120.712 8.98278 121.145 8.98278H126.786C127.755 8.98278 128.158 10.2224 127.374 10.7918L122.81 14.1074C122.46 14.362 122.313 14.8134 122.447 15.2254L124.19 20.5902C124.49 21.5115 123.435 22.2776 122.651 21.7082L118.088 18.3926C117.737 18.138 117.263 18.138 116.912 18.3926L112.349 21.7082C111.565 22.2776 110.51 21.5115 110.81 20.5902L112.553 15.2254C112.687 14.8134 112.54 14.362 112.19 14.1074L107.626 10.7918C106.842 10.2224 107.245 8.98278 108.214 8.98278H113.855C114.288 8.98278 114.672 8.70382 114.806 8.2918L116.549 2.92705Z"
                        fill="#FF8A00"
                      />
                    </svg>
                  </div>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.
                  </p>
                </div>
              </div>
              <div>
                <div className="testimonial-card">
                  <div className="flex justify-center">
                    <svg
                      width="89"
                      height="88"
                      viewBox="0 0 89 88"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="44.5" cy="44" r="44" fill="#BC0069" />
                    </svg>
                  </div>
                  <h3>Saritha</h3>
                  <div className="flex justify-center">
                    <svg
                      width="131"
                      height="26"
                      viewBox="0 0 131 26"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12.5489 2.92705C12.8483 2.00574 14.1517 2.00574 14.4511 2.92705L16.1942 8.29179C16.328 8.70382 16.712 8.98278 17.1452 8.98278H22.7861C23.7548 8.98278 24.1576 10.2224 23.3738 10.7918L18.8103 14.1074C18.4598 14.362 18.3132 14.8134 18.447 15.2254L20.1902 20.5902C20.4895 21.5115 19.435 22.2776 18.6513 21.7082L14.0878 18.3926C13.7373 18.138 13.2627 18.138 12.9122 18.3926L8.34869 21.7082C7.56498 22.2776 6.5105 21.5115 6.80985 20.5902L8.55296 15.2254C8.68683 14.8134 8.54018 14.362 8.18969 14.1074L3.62616 10.7918C2.84245 10.2224 3.24523 8.98278 4.21395 8.98278H9.85477C10.288 8.98278 10.672 8.70382 10.8058 8.2918L12.5489 2.92705Z"
                        fill="#FF8A00"
                      />
                      <path
                        d="M38.5489 2.92705C38.8483 2.00574 40.1517 2.00574 40.4511 2.92705L42.1942 8.29179C42.328 8.70382 42.712 8.98278 43.1452 8.98278H48.7861C49.7548 8.98278 50.1576 10.2224 49.3738 10.7918L44.8103 14.1074C44.4598 14.362 44.3132 14.8134 44.447 15.2254L46.1902 20.5902C46.4895 21.5115 45.435 22.2776 44.6513 21.7082L40.0878 18.3926C39.7373 18.138 39.2627 18.138 38.9122 18.3926L34.3487 21.7082C33.565 22.2776 32.5105 21.5115 32.8098 20.5902L34.553 15.2254C34.6868 14.8134 34.5402 14.362 34.1897 14.1074L29.6262 10.7918C28.8425 10.2224 29.2452 8.98278 30.2139 8.98278H35.8548C36.288 8.98278 36.672 8.70382 36.8058 8.2918L38.5489 2.92705Z"
                        fill="#FF8A00"
                      />
                      <path
                        d="M64.5489 2.92705C64.8483 2.00574 66.1517 2.00574 66.4511 2.92705L68.1942 8.29179C68.328 8.70382 68.712 8.98278 69.1452 8.98278H74.7861C75.7548 8.98278 76.1576 10.2224 75.3738 10.7918L70.8103 14.1074C70.4598 14.362 70.3132 14.8134 70.447 15.2254L72.1902 20.5902C72.4895 21.5115 71.435 22.2776 70.6513 21.7082L66.0878 18.3926C65.7373 18.138 65.2627 18.138 64.9122 18.3926L60.3487 21.7082C59.565 22.2776 58.5105 21.5115 58.8098 20.5902L60.553 15.2254C60.6868 14.8134 60.5402 14.362 60.1897 14.1074L55.6262 10.7918C54.8425 10.2224 55.2452 8.98278 56.2139 8.98278H61.8548C62.288 8.98278 62.672 8.70382 62.8058 8.2918L64.5489 2.92705Z"
                        fill="#FF8A00"
                      />
                      <path
                        d="M90.5489 2.92705C90.8483 2.00574 92.1517 2.00574 92.4511 2.92705L94.1942 8.29179C94.328 8.70382 94.712 8.98278 95.1452 8.98278H100.786C101.755 8.98278 102.158 10.2224 101.374 10.7918L96.8103 14.1074C96.4598 14.362 96.3132 14.8134 96.447 15.2254L98.1902 20.5902C98.4895 21.5115 97.435 22.2776 96.6513 21.7082L92.0878 18.3926C91.7373 18.138 91.2627 18.138 90.9122 18.3926L86.3487 21.7082C85.565 22.2776 84.5105 21.5115 84.8098 20.5902L86.553 15.2254C86.6868 14.8134 86.5402 14.362 86.1897 14.1074L81.6262 10.7918C80.8425 10.2224 81.2452 8.98278 82.2139 8.98278H87.8548C88.288 8.98278 88.672 8.70382 88.8058 8.2918L90.5489 2.92705Z"
                        fill="#FF8A00"
                      />
                      <path
                        d="M116.549 2.92705C116.848 2.00574 118.152 2.00574 118.451 2.92705L120.194 8.29179C120.328 8.70382 120.712 8.98278 121.145 8.98278H126.786C127.755 8.98278 128.158 10.2224 127.374 10.7918L122.81 14.1074C122.46 14.362 122.313 14.8134 122.447 15.2254L124.19 20.5902C124.49 21.5115 123.435 22.2776 122.651 21.7082L118.088 18.3926C117.737 18.138 117.263 18.138 116.912 18.3926L112.349 21.7082C111.565 22.2776 110.51 21.5115 110.81 20.5902L112.553 15.2254C112.687 14.8134 112.54 14.362 112.19 14.1074L107.626 10.7918C106.842 10.2224 107.245 8.98278 108.214 8.98278H113.855C114.288 8.98278 114.672 8.70382 114.806 8.2918L116.549 2.92705Z"
                        fill="#FF8A00"
                      />
                    </svg>
                  </div>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.
                  </p>
                </div>
              </div>
              <div>
                <div className="testimonial-card">
                  <div className="flex justify-center">
                    <svg
                      width="89"
                      height="88"
                      viewBox="0 0 89 88"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="44.5" cy="44" r="44" fill="#BC0069" />
                    </svg>
                  </div>
                  <h3>Saritha</h3>
                  <div className="flex justify-center">
                    <svg
                      width="131"
                      height="26"
                      viewBox="0 0 131 26"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12.5489 2.92705C12.8483 2.00574 14.1517 2.00574 14.4511 2.92705L16.1942 8.29179C16.328 8.70382 16.712 8.98278 17.1452 8.98278H22.7861C23.7548 8.98278 24.1576 10.2224 23.3738 10.7918L18.8103 14.1074C18.4598 14.362 18.3132 14.8134 18.447 15.2254L20.1902 20.5902C20.4895 21.5115 19.435 22.2776 18.6513 21.7082L14.0878 18.3926C13.7373 18.138 13.2627 18.138 12.9122 18.3926L8.34869 21.7082C7.56498 22.2776 6.5105 21.5115 6.80985 20.5902L8.55296 15.2254C8.68683 14.8134 8.54018 14.362 8.18969 14.1074L3.62616 10.7918C2.84245 10.2224 3.24523 8.98278 4.21395 8.98278H9.85477C10.288 8.98278 10.672 8.70382 10.8058 8.2918L12.5489 2.92705Z"
                        fill="#FF8A00"
                      />
                      <path
                        d="M38.5489 2.92705C38.8483 2.00574 40.1517 2.00574 40.4511 2.92705L42.1942 8.29179C42.328 8.70382 42.712 8.98278 43.1452 8.98278H48.7861C49.7548 8.98278 50.1576 10.2224 49.3738 10.7918L44.8103 14.1074C44.4598 14.362 44.3132 14.8134 44.447 15.2254L46.1902 20.5902C46.4895 21.5115 45.435 22.2776 44.6513 21.7082L40.0878 18.3926C39.7373 18.138 39.2627 18.138 38.9122 18.3926L34.3487 21.7082C33.565 22.2776 32.5105 21.5115 32.8098 20.5902L34.553 15.2254C34.6868 14.8134 34.5402 14.362 34.1897 14.1074L29.6262 10.7918C28.8425 10.2224 29.2452 8.98278 30.2139 8.98278H35.8548C36.288 8.98278 36.672 8.70382 36.8058 8.2918L38.5489 2.92705Z"
                        fill="#FF8A00"
                      />
                      <path
                        d="M64.5489 2.92705C64.8483 2.00574 66.1517 2.00574 66.4511 2.92705L68.1942 8.29179C68.328 8.70382 68.712 8.98278 69.1452 8.98278H74.7861C75.7548 8.98278 76.1576 10.2224 75.3738 10.7918L70.8103 14.1074C70.4598 14.362 70.3132 14.8134 70.447 15.2254L72.1902 20.5902C72.4895 21.5115 71.435 22.2776 70.6513 21.7082L66.0878 18.3926C65.7373 18.138 65.2627 18.138 64.9122 18.3926L60.3487 21.7082C59.565 22.2776 58.5105 21.5115 58.8098 20.5902L60.553 15.2254C60.6868 14.8134 60.5402 14.362 60.1897 14.1074L55.6262 10.7918C54.8425 10.2224 55.2452 8.98278 56.2139 8.98278H61.8548C62.288 8.98278 62.672 8.70382 62.8058 8.2918L64.5489 2.92705Z"
                        fill="#FF8A00"
                      />
                      <path
                        d="M90.5489 2.92705C90.8483 2.00574 92.1517 2.00574 92.4511 2.92705L94.1942 8.29179C94.328 8.70382 94.712 8.98278 95.1452 8.98278H100.786C101.755 8.98278 102.158 10.2224 101.374 10.7918L96.8103 14.1074C96.4598 14.362 96.3132 14.8134 96.447 15.2254L98.1902 20.5902C98.4895 21.5115 97.435 22.2776 96.6513 21.7082L92.0878 18.3926C91.7373 18.138 91.2627 18.138 90.9122 18.3926L86.3487 21.7082C85.565 22.2776 84.5105 21.5115 84.8098 20.5902L86.553 15.2254C86.6868 14.8134 86.5402 14.362 86.1897 14.1074L81.6262 10.7918C80.8425 10.2224 81.2452 8.98278 82.2139 8.98278H87.8548C88.288 8.98278 88.672 8.70382 88.8058 8.2918L90.5489 2.92705Z"
                        fill="#FF8A00"
                      />
                      <path
                        d="M116.549 2.92705C116.848 2.00574 118.152 2.00574 118.451 2.92705L120.194 8.29179C120.328 8.70382 120.712 8.98278 121.145 8.98278H126.786C127.755 8.98278 128.158 10.2224 127.374 10.7918L122.81 14.1074C122.46 14.362 122.313 14.8134 122.447 15.2254L124.19 20.5902C124.49 21.5115 123.435 22.2776 122.651 21.7082L118.088 18.3926C117.737 18.138 117.263 18.138 116.912 18.3926L112.349 21.7082C111.565 22.2776 110.51 21.5115 110.81 20.5902L112.553 15.2254C112.687 14.8134 112.54 14.362 112.19 14.1074L107.626 10.7918C106.842 10.2224 107.245 8.98278 108.214 8.98278H113.855C114.288 8.98278 114.672 8.70382 114.806 8.2918L116.549 2.92705Z"
                        fill="#FF8A00"
                      />
                    </svg>
                  </div>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.
                  </p>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Testimonial;
