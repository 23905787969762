import React from 'react'
// import Header from "../../Header"
import "../dealers/Style.css"
import Patnerlogo1 from "../../../assets/img/patnerlogo1.webp"
import Patnerlogo2 from "../../../assets/img/patnerlogo2.webp"
import Patnerlogo3 from "../../../assets/img/patnerlogo3.webp"
import Patnerlogo4 from "../../../assets/img/patnerlogo4.webp"
import Patnerlogo5 from "../../../assets/img/patnerlogo5.webp"
import Patnerlogo6 from "../../../assets/img/patnerlogo6.webp"
import Patnerlogo7 from "../../../assets/img/patnerlogo7.webp"
import Patnerlogo8 from "../../../assets/img/patnerlogo8.webp"
import Patnerlogo9 from "../../../assets/img/patnerlogo9.webp"
import Patnerlogo10 from "../../../assets/img/patnerlogo10.webp"
import Patnerlogo11 from "../../../assets/img/patnerlogo11.webp"
import Patnerlogo12 from "../../../assets/img/patnerlogo12.webp"
import Patnerlogo13 from "../../../assets/img/patnerlogo13.webp"
import Patnerlogo14 from "../../../assets/img/patnerlogo14.webp"
import Patnerlogo15 from "../../../assets/img/patnerlogo15.webp"
import Patnerlogo16 from "../../../assets/img/patnerlogo16.webp"
import Patnerlogo17 from "../../../assets/img/patnerlogo17.webp"
import { FaChevronLeft } from "react-icons/fa6";
import { FaChevronRight } from "react-icons/fa6";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// Example of using Bootstrap's no-conflict mode
import 'bootstrap/dist/css/bootstrap.min.css';


function Dealers() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    
  
  };

  return (
    <div className='bgdealers'>
        <div className='container'>
        {/* <Header textColor='black'/> */}
        <div className='lg:mt-[100px] md:mt-[100px] lg:mb-[100px] md:mb-[100px] mt-[40px]'>
<p className='text-[#000] text-center text-[40px] font-medium'>Trusted by Industry Leaders</p>
<p className='text-[#454545] text-center text-[13px] font-normal m-auto md:w-[712px] lg:w-[792px]'>We take pride in serving a prestigious roster of premium clients who have placed their trust in Metasoft IT Solutions. These industry-leading organizations rely on our expertise in IT infrastructure solutions, website development, digital marketing and innovative technologies.</p>

        
        <Slider
        {...settings}
        className="Delers mt-5 mb-5 mx-auto ms-3 me-3"
      >
         <div className="">
          <div className='flex flex-wrap  items-center justify-center '>
          <img className="p-3 object-contain " src={Patnerlogo1} alt="Clientsliderimg1"></img>
          <img src={Patnerlogo2} className="p-3 object-contain" alt="Clientsliderimg2"></img>
          <img src={Patnerlogo3} className="p-3 object-contain" alt="Clientsliderimg3"></img>
          <img src={Patnerlogo4} className="p-3 object-contain" alt="Clientsliderimg4"></img>
          <img src={Patnerlogo5} className="p-3 object-contain" alt="Clientsliderimg5"></img>
          <img src={Patnerlogo6} className="p-3 object-contain" alt="Clientsliderimg6"></img>
          </div>
          <div className='flex flex-wrap  items-center justify-center'>
         
          <img src={Patnerlogo7} className="p-3 object-contain" alt="Clientsliderimg2"></img>
          <img src={Patnerlogo8} className="p-3 object-contain" alt="Clientsliderimg3"></img>
          <img src={Patnerlogo9} className="p-3 object-contain" alt="Clientsliderimg4"></img>
          <img src={Patnerlogo10} className="p-3 object-contain" alt="Clientsliderimg5"></img>
          <img src={Patnerlogo11} className="p-3 object-contain" alt="Clientsliderimg6"></img>
          <img className="p-3 object-contain" src={Patnerlogo12} alt="Clientsliderimg1"></img>
          </div>
          <div className='flex flex-wrap  items-center justify-center'>
      
          <img src={Patnerlogo13} className="p-3 object-contain" alt="Clientsliderimg2"></img>
          <img src={Patnerlogo14} className="p-3 object-contain" alt="Clientsliderimg3"></img>
          <img src={Patnerlogo15} className="p-3 object-contain" alt="Clientsliderimg4"></img>
          <img src={Patnerlogo16} className="p-3 object-contain" alt="Clientsliderimg5"></img>
          <img src={Patnerlogo17} className="p-3 object-contain" alt="Clientsliderimg6"></img>
          </div>
         </div>

         <div className="">
          <div className='flex flex-wrap  items-center justify-center '>
          <img className="p-3 object-contain " src={Patnerlogo1} alt="Clientsliderimg1"></img>
          <img src={Patnerlogo2} className="p-3 object-contain" alt="Clientsliderimg2"></img>
          <img src={Patnerlogo3} className="p-3 object-contain" alt="Clientsliderimg3"></img>
          <img src={Patnerlogo4} className="p-3 object-contain" alt="Clientsliderimg4"></img>
          <img src={Patnerlogo5} className="p-3 object-contain" alt="Clientsliderimg5"></img>
          <img src={Patnerlogo6} className="p-3 object-contain" alt="Clientsliderimg6"></img>
          </div>
          <div className='flex flex-wrap  items-center justify-center'>
         
          <img src={Patnerlogo7} className="p-3 object-contain" alt="Clientsliderimg2"></img>
          <img src={Patnerlogo8} className="p-3 object-contain" alt="Clientsliderimg3"></img>
          <img src={Patnerlogo9} className="p-3 object-contain" alt="Clientsliderimg4"></img>
          <img src={Patnerlogo10} className="p-3 object-contain" alt="Clientsliderimg5"></img>
          <img src={Patnerlogo11} className="p-3 object-contain" alt="Clientsliderimg6"></img>
          <img className="p-3 object-contain" src={Patnerlogo12} alt="Clientsliderimg1"></img>
          </div>
          <div className='flex flex-wrap  items-center justify-center'>
      
          <img src={Patnerlogo13} className="p-3 object-contain" alt="Clientsliderimg2"></img>
          <img src={Patnerlogo14} className="p-3 object-contain" alt="Clientsliderimg3"></img>
          <img src={Patnerlogo15} className="p-3 object-contain" alt="Clientsliderimg4"></img>
          <img src={Patnerlogo16} className="p-3 object-contain" alt="Clientsliderimg5"></img>
          <img src={Patnerlogo17} className="p-3 object-contain" alt="Clientsliderimg6"></img>
          </div>
         </div>
      
        
    </Slider>
       
  
          {/* <div className="im">
        <div className='flex '>
<img src={Patnerlogo1} alt='Patnerlogo'></img>
<img src={Patnerlogo2} alt='Patnerlogo1'></img>
<img src={Patnerlogo3} alt='Patnerlogo1'></img>
<img src={Patnerlogo4} alt='Patnerlogo1'></img>
<img src={Patnerlogo5} alt='Patnerlogo1'></img>
<img src={Patnerlogo6} alt='Patnerlogo1'></img>
</div>
<div className='flex'>
<img src={Patnerlogo7} alt='Patnerlogo1'></img>
<img src={Patnerlogo8} alt='Patnerlogo1'></img>
<img src={Patnerlogo9} alt='Patnerlogo1'></img>
<img src={Patnerlogo10} alt='Patnerlogo'></img>
<img src={Patnerlogo11} alt='Patnerlogo1'></img>
<img src={Patnerlogo12} alt='Patnerlogo1'></img>
<img src={Patnerlogo13} alt='Patnerlogo1'></img>
</div>
<div className='flex'>
<img src={Patnerlogo14} alt='Patnerlogo1'></img>
<img src={Patnerlogo15} alt='Patnerlogo1'></img>
<img src={Patnerlogo16} alt='Patnerlogo1'></img>
<img src={Patnerlogo17} alt='Patnerlogo1'></img>


        </div>
          </div>
          <div className="item">
        <div className='flex '>
<img src={Patnerlogo1} alt='Patnerlogo'></img>
<img src={Patnerlogo2} alt='Patnerlogo1'></img>
<img src={Patnerlogo3} alt='Patnerlogo1'></img>
<img src={Patnerlogo4} alt='Patnerlogo1'></img>
<img src={Patnerlogo5} alt='Patnerlogo1'></img>
<img src={Patnerlogo6} alt='Patnerlogo1'></img>
</div>
<div className='flex'>
<img src={Patnerlogo7} alt='Patnerlogo1'></img>
<img src={Patnerlogo8} alt='Patnerlogo1'></img>
<img src={Patnerlogo9} alt='Patnerlogo1'></img>
<img src={Patnerlogo10} alt='Patnerlogo'></img>
<img src={Patnerlogo11} alt='Patnerlogo1'></img>
<img src={Patnerlogo12} alt='Patnerlogo1'></img>
<img src={Patnerlogo13} alt='Patnerlogo1'></img>
</div>
<div className='flex'>
<img src={Patnerlogo14} alt='Patnerlogo1'></img>
<img src={Patnerlogo15} alt='Patnerlogo1'></img>
<img src={Patnerlogo16} alt='Patnerlogo1'></img>
<img src={Patnerlogo17} alt='Patnerlogo1'></img>


        </div>
          </div>
         */}

        {/* <FaChevronLeft />
        <FaChevronRight /> */}

        </div></div>
    </div>
  )
}

export default Dealers