import React from 'react'
// import Header from "../../Header"
import "../services/Style.css"
import Serviceimg1 from "../../../assets/img/serviceimg1.webp"
import Serviceimg2 from "../../../assets/img/serviceimg2.webp"
import Serviceimg3 from "../../../assets/img/serviceimg3.webp"
import { Link } from 'react-router-dom'
function Services() {
  return (
    <>
    <div className='Bgservices' id="servicesection">
   
    <div className='container'>
   {/* <Header textColor='white'/> */}
        <div className='flex flex-col justify-center items-center h-full text-center mt-[50px] '>
          <div>
            <p className='text-white text-center lg:text-[53px] md:text-[53px] text-[33px] font-medium leading-[72px] lg:w-[1085px]'>Our Services</p>
<div className='flex justify-center items-center lg:mt-[30px] md:mt-[30px]'>
<p className='text-[#D2CBCB] text-center text-[12px] font-normal md:w-[450px] lg:w-[559px]'>Empowering Efficiency and Functionality: Providing a Diverse Range of Hardware Solutions and Systems to Drive Technological Advancements.</p>
</div>

          </div>

        </div>
        <div className='lg:flex md:flex justify-around lg:mt-[30px] mt-[30px]'>
<div className='relative lg:mt-[0px] mt-[30px]'>
<img src={Serviceimg1} alt='Serviceimg1'></img>
<div className='absolute bottom-2 text-start left-5'>
    <p className='text-white text-[17px] font-semibold'>IT Infrastructure & Services</p>
    <p className='text-[#D2CBCB] text-[12px] font-normal w-[90%]'>Catalyzing Technological Foundations: Unveiling a Spectrum of IT Infrastructure and Services for Seamless Digital Evolution.</p>
</div>
</div>
<div className='relative lg:mt-[0px] mt-[30px]'>
<img src={Serviceimg2} alt='Serviceimg2'></img>
<div className='absolute bottom-3 text-start left-5'>
    <p className='text-white text-[17px] font-semibold'>Hardware Supply & Systems</p>
    <p className='text-[#D2CBCB] text-[12px] font-normal w-[90%]'>Empowering Efficiency and Functionality: Providing a Diverse Range of Hardware Solutions and Systems to Drive Technological Advancements.</p>
</div>
</div>
<div className='relative lg:mt-[0px] mt-[30px]'>
<img src={Serviceimg3} alt='Serviceimg3'></img>
<div className='absolute bottom-2 text-start left-5'>
    <p className='text-white text-[17px] font-semibold'>Security & Communication Solutions</p>
    <p className='text-[#D2CBCB] text-[12px] font-normal w-[90%]'>Elevating Protection and Connectivity: Delivering Integrated Security and Communication Solutions to Safeguard Environments and Foster Seamless Interactions</p>
</div>
</div>
        </div>
        <div className='flex justify-center mb-[50px] mt-[50px]'>
         <Link to="/ourservices">
            <button className='btnservice'>Know More</button></Link>
        </div>
        </div>
    </div>
    
    </>
  )
}

export default Services