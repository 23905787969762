import React from "react";
import Banner from "../home/banner/Banner";
import Service from "../home/services/Services";
import About from "../home/about/About";
import AboutVission from "../home/about/AboutVision";
import WhyMetasoft from "../home/whymetasoft/WhyMetasoft";
import Testimonail from "../home/testimonial/Testimonial";
import Dealers from "../home/dealers/Dealers";
import Footer from "../home/footer/Footer";
function Home() {
  return (
    <>
      <Banner />
      <Service />
      <About />
      <AboutVission />
      <WhyMetasoft />
      {/* <Testimonail />  */}
      <Dealers />
      <Footer />
    </>
  );
}

export default Home;
