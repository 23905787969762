import React from 'react'

import Logo from "../assets/img/Logo.webg"
import { Link } from 'react-router-dom'
function Heder({ textColor }) {
  return (
    <>
        <div className='flex justify-between items-center'>
          <div>
<Link to="/"><img src={Logo} alt='Logo'></img></Link>
          </div>

          <div>
            <p className={`text-${textColor} text-[18px] font-light leading-7`}>Since 2018</p>
          </div>
        </div>
    </>
  )
}

export default Heder