import React, { useState } from "react";
import "../banner/Style.css";
import Header from "../../Header";
import Menubg from "../../../assets/img/Menu button.webp";

import Backgroundframebanner from "../../../assets/img/backgroundframebannerimg.webp";
import Metasoftlogo from "../../../assets/img/metasoftlogo.svg";
import { Link } from "react-router-dom";
import Model from "../../model/Model";
function Banner({ activeLink }) {
  const scrollToNextSection = () => {
    const nextSection = document.getElementById("servicesection");

    if (nextSection) {
      window.scrollTo({
        top: nextSection.offsetTop,
        behavior: "smooth",
      });
    }
  };
  return (
    <>
      <div>
        <div className="Bgbanner relative">
          <div className="absolute w-100  h-100 top-0 full-width-container">
            <img
              src={Backgroundframebanner}
              alt="Backgroundframebanner"
              className=" object-cover w-100  h-100 "
            ></img>
          </div>
          <div className="container">
            <Header textColor="white" className="fixed" />
            <div className="flex  justify-center items-center  text-center  lg:mt-[150px] md:mt-[300px] mt-[150px]">
              <div>
                <p className="text-white text-center lg:text-[53px] md:text-[53px]  text-[33px] font-medium lg:leading-[72px] lg:w-[1085px] z-10 relative">
                  Elevate Your IT Experience: Solutions Tailored for Success.
                </p>
                <div className="flex justify-center items-center mt-[50px]">
                  <button
                    className="btn-banner z-10 relative hover:bg-[#C52891]"
                    onClick={scrollToNextSection}
                  >
                    <Link to="#" className="no-underline text-white">
                      EXPLORE NOW
                    </Link>
                  </button>
                </div>
              </div>
            </div>

            <div className="flex justify-end items-center">
              <img
                src={Menubg}
                alt="menubg"
                data-bs-toggle="modal"
                data-bs-target="#menu"
                className="fixed z-10 cursor-pointer bottom-0"
              ></img>
            </div>
          </div>
        </div>
      </div>
      <Model activeLink={activeLink} />
    </>
  );
}

export default Banner;
