import React, { useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import Metasoftlogo from "../../assets/img/metasoftlogo.svg";
import Menuclose from "../../assets/img/closemenu.webp";
import Logo from "../../assets/img/Logo.webg";
import "../model/Style.css";
function Model({ activeLink }) {
  const navigate = useNavigate();
  const modalRef = useRef(null);
  const handleLinkClick = (path) => {
    // Close the modal before navigating to the new page

    const modalInstance = new window.bootstrap.Modal(modalRef.current);
    modalInstance.hide();
    // Redirect to the specified page after a delay (you can customize the delay)

    setTimeout(() => {
      navigate(path);
    }, 500);
  };

  return (
    <div
      class="menu modal relative fade bd-example-modal-lg"
      id="menu"
      tabindex="-1"
      aria-labelledby="menu_Label"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div className="container">
            <div className="d-flex justify-content-end ">
              <img
                src={Menuclose}
                alt="menubg"
                data-bs-toggle="modal"
                data-bs-target="#menu"
                className="absolute bottom-0 cursor-pointer"
              ></img>
            </div>
            <div class="modal-header flex justify-between">
              <Link to="/" onClick={() => handleLinkClick("/")}>
                <img src={Metasoftlogo} alt="metasoftlogo"></img>
              </Link>
              <Link to="/" onClick={() => handleLinkClick("/")}>
                <img
                  src={Logo}
                  alt="logo"
                  className="lg:hidden md:hidden block"
                ></img>
              </Link>
            </div>
            <div class="modal-body">
              <div className="lg:flex md:flex lg:justify-around md:justify-around  items-center mt-1">
                <div className="mainMenu">
                  <ul className="text-white text-[48px] font-normal text-start p-3">
                    <Link
                      to="/"
                      onClick={() => handleLinkClick("/")}
                      className={`no-underline ${
                        activeLink === "home" ? "text-[#C52891]" : "text-white"
                      }`}
                    >
                      <li className="hover:text-[#C52891]">Home</li>
                    </Link>
                    <Link
                      to="/about"
                      onClick={() => handleLinkClick("/about")}
                      className={`no-underline ${
                        activeLink === "home" ? "text-[#C52891]" : "text-white"
                      }`}
                    >
                      {" "}
                      <li className="hover:text-[#C52891]">About Us</li>
                    </Link>
                    <Link
                      to="/contact"
                      onClick={() => handleLinkClick("/contact")}
                      className={`no-underline ${
                        activeLink === "home" ? "text-[#C52891]" : "text-white"
                      }`}
                    >
                      {" "}
                      <li className="hover:text-[#C52891]">Get In Touch</li>
                    </Link>
                    <Link
                      to="/ourservices"
                      onClick={() => handleLinkClick("/ourservices")}
                      className={`no-underline ${
                        activeLink === "home" ? "text-[#C52891]" : "text-white"
                      }`}
                    >
                      {" "}
                      <li className="hover:text-[#C52891]">Our Services </li>
                    </Link>

                    <Link
                      to="/ourbrands"
                      onClick={() => handleLinkClick("/ourbrands")}
                      className={`no-underline ${
                        activeLink === "home" ? "text-[#C52891]" : "text-white"
                      }`}
                    >
                      <li className="hover:text-[#C52891]">Our Brands</li>
                    </Link>
                  </ul>
                </div>
                <div className="lg:block md:block hidden">
                  <svg
                    width="250"
                    height="250"
                    viewBox="0 0 306 307"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="rotating-image"
                  >
                    <path
                      d="M153.391 197.049C183.745 197.049 208.146 222.189 207.999 253.244C207.852 283.56 183.231 307.074 151.7 307C122.081 307 99.2229 282.673 99.0024 251.322C98.7084 221.672 125.535 195.718 153.391 197.049Z"
                      fill="#ED1E7C"
                    />
                    <path
                      d="M151.558 109.999C123.973 110.147 100.14 86.8648 99.0364 55.9206C97.933 25.9342 122.061 0 152.441 0C182.379 0 205.992 22.1767 207.904 52.4578C209.596 79.6445 188.779 109.778 151.558 109.999Z"
                      fill="#4EB7DA"
                    />
                    <path
                      d="M53.6947 98.075C84.281 97.708 108.779 121.636 108.999 152.317C109.146 179.695 87.655 206.926 54.0615 207C24.0621 207.073 0.443855 183.145 0.00376583 152.024C-0.362975 123.471 26.1158 96.3868 53.6947 98.075Z"
                      fill="#F0422B"
                    />
                    <path
                      d="M250.879 98.0119C281.926 97.7919 305.853 122.068 306 153.091C306.073 180.887 283.247 207.363 249.704 206.996C220.566 206.63 197.519 182.427 197.005 151.624C196.565 122.068 224.235 96.3251 250.879 98.0853V98.0119Z"
                      fill="#87C635"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div className="flex flex-wrap mt-1 justify-between ">
              <Link to="" className="no-underline" target="_blank">
                {" "}
                <p className="text-white text-[15px] font-medium hover:text-[#C52891]">
                  Digital Marketing
                </p>
              </Link>
              <Link
                to="https://metasoft.ae/"
                className="no-underline"
                target="_blank"
              >
                {" "}
                <p className="text-white text-[15px] font-medium  hover:text-[#C52891]">
                  Web Development
                </p>
              </Link>
            </div>
            <div class="modal-footer  d-flex justify-content-between align-items-center gap-5 pb-5 ">
              <ul className="d-flex justify-content-between align-items-center  gap-5 text-white text-[18px]  font-normal">
                <Link
                  to="https://www.linkedin.com/company/31008439/admin/feed/posts/"
                  className="no-underline"
                  target="_blank"
                >
                  {" "}
                  <li className="text-white text-[18px] font-normal  hover:text-[#C52891]">
                    linkedin
                  </li>
                </Link>
                <Link
                  to="https://www.facebook.com/metasoftitsolutions"
                  className="no-underline"
                  target="_blank"
                >
                  {" "}
                  <li className="text-white text-[18px] font-normal  hover:text-[#C52891]">
                    facebook
                  </li>
                </Link>
                <Link
                  to="https://www.instagram.com/metasoft_it/"
                  className="no-underline"
                  target="_blank"
                >
                  <li className="text-white text-[18px] font-normal  hover:text-[#C52891]">
                    instagram
                  </li>
                </Link>
                <button
                  type="button"
                  class="close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true" className="text-white text-[30px]">
                    &times;
                  </span>
                </button>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Model;
