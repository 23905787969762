import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Homepage from "../src/components/home/Home"
import About from "../src/components/about/About"
import OurBrands from "../src/components/ourbrands/OurBrands"
import Contact from "../src/components/contact/OurCareers"
import OurServices from "../src/components/ourservice/OurServices"
import Mastery from "../src/components/ourservice/Mastery"
import Automation from "../src/components/ourservice/Automation"
import Iot from "../src/components/ourservice/Iot"
import Ai from '../src/components/ourservice/Ai';
function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/about" element={<About />} />
          <Route path="/ourbrands" element={<OurBrands />} />
          <Route path="/ourservices" element={<OurServices />} />
          <Route path="/mastery" element={<Mastery />} />
          <Route path="/automation" element={<Automation />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/iot" element={<Iot />} />
          <Route path="/ai" element={<Ai />} />
          
        </Routes>
      </Router>
    </div>
  );
}

export default App;
